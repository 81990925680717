import React from "react"

const Book = ({ title, link, text, imageLink, flair }) => {
    return (
        <div className="col-12 book">
            <h5 className="font-weight-bold">
                <a className="text-decoration-none" href={link} target="_blank" rel="noopener noreferrer">{title}</a>
            </h5>
            <p className="mb-5">{text}</p>
        </div>
    )
}

export default Book
