import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Book from "../components/book"

import books from "../../content/data/books.json"

const Books = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Book Recommendations"
        description=""
        image="/books.jpg"
      />
      <h1 className="display-4 mt-3 mb-5">Book Recommendations</h1>

      <div class="row">

        {books.map(item => {
          return (
            <Book
              title={item.title}
              link={item.link}
              imageLink={item.imageLink}
              text={item.text}
              flair={item.flair}
            />
          )
        })}
      </div>


      <div class="alert alert-success" role="alert">

        <p>By clicking one of the books above, I may earn a small provision in case you buy something.<br />
        This does not cost you anything.</p>
        <hr />
        <p class="mb-0"><strong>Thanks a lot!</strong> ❤</p>
      </div>

    </Layout>
  )
}

export default Books

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
